import {
  ProSocialLinkProviderInfo,
  SocialLinkProviderInfo,
} from "@/types/user";
import utils from "@/utils/constants";
export const validateEmail = (email: string): string => {
  if (!email) return "You must add your email address";
  if (!email.includes("@"))
    return "You must add @ character to your email address";

  const emailParts = email.split("@");
  if (emailParts.length > 2) return "You can use only one @ character";

  const localPart = emailParts[0];
  const localPartLength = localPart.length;
  const specialCharacters = '?"#$%&`*+–_./|^{}~';

  if (!localPartLength)
    return "You must add the local part of the email (portion before @)";
  if (localPartLength > 64)
    return "You can not add more than 64 characters in the local part of the email";
  if (
    specialCharacters.includes(localPart.charAt(0)) ||
    specialCharacters.includes(localPart.charAt(localPartLength - 1))
  )
    return "You can not add special characters as the first or last character of the local part";
  if (!emailParts[1].includes("."))
    return "You must add . character in the domain part of the email";

  const domainPart = emailParts[1].split(".")[0];
  const domainPartLength = domainPart.length;
  if (!domainPartLength)
    return "You must add the domain part of the email (portion after @)";
  if (
    specialCharacters.includes(domainPart.charAt(0)) ||
    specialCharacters.includes(domainPart.charAt(domainPartLength - 1))
  )
    return "You can not add special characters as the first or last character of the domain part";

  const remainingPart = email.split("@")[1].split(".")[1];
  if (!remainingPart.length)
    return "You must add the last part of the email (portion after .)";
  if (remainingPart.length < 2)
    return "You must add at least two characters to the last part of the email";

  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regexp.test(email)) return "You can not use other special characters";

  return "";
};

export const validatePassword = (password: string): string => {
  if (!password) return "You must add a password";
  if (password.length < 8) return "Password must be at least 8 characters";
  return "";
};

export const validateConfirmPassword = (
  password: string,
  confirmPassword: string,
): string => {
  if (password != confirmPassword)
    return "Please make sure your password match";
  return "";
};

export const validateSelectField = (name: string, field: string): string => {
  if (!name) return `You must select a ${field}`;
  return "";
};

export const validatePhone = (
  phone: string,
  dialcode: boolean,
  field: string,
): string => {
  const phonenr = phone.split(" ").join("");
  const regexp = dialcode
    ? /^([0-9]{1}[-\s.]*){7,}$/
    : /^(\+\d{1,6}[-\s.]*)?([0-9]{1}[-\s.]*){7,}$/;
  if (!phonenr) return `You must add ${field} phone number`;
  if (!regexp.test(phonenr)) return "Invalid phone number";
  return "";
};

export const validateField = (
  name: string,
  fieldName: string,
  fieldType: "input" | "select",
): string => {
  if (!name)
    return fieldType === "input"
      ? `You must add ${fieldName}`
      : `You must select a ${fieldName}`;
  return "";
};

const validateMultiSelectField = (
  array: string[],
  fieldName: string,
): string => {
  if (!array.length) return `You must select at least one ${fieldName}`;
  return "";
};

export const validateLink = (link: string): string => {
  const linkRegex =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  const isValid = linkRegex.test(link);
  if (!isValid) {
    return "Please enter a valid link";
  }
  return "";
};
export const validateMonth = (month: string): string => {
  return utils.months.includes(month) ? "" : "Please select a valid month";
};

export const validateYear = (year: number): string => {
  return year >= 1950 && year <= new Date().getFullYear()
    ? ""
    : "Please select a valid year";
};

export const validateSocialLink = (
  link: string,
  provider: string,
  pro: boolean,
) => {
  const userLinkProvider = pro
    ? ProSocialLinkProviderInfo
    : SocialLinkProviderInfo;
  const linkProviderIndex = userLinkProvider.findIndex(
    (el) => el.value === provider,
  );
  const linkProvider = userLinkProvider[linkProviderIndex];
  const regex = new RegExp(
    "^(https?://)?(www.)?(" +
      `${linkProvider?.escapedLabel}` +
      ")?([a-zA-Z0-9_.]+)/?$",
  );

  if (link && !link.match(regex)) {
  }

  if (link && linkProvider && link.match(regex)) {
    const cutOut = new RegExp(
      "^(https?://)?(www.)?(" + linkProvider?.escapedLabel + ")?",
    );
    return (link.startsWith("@") ? link.substring(1) : link).replace(
      cutOut,
      "",
    );
  }
  return link.startsWith("@") ? link.substring(1) : link;
};
